<template>
  <router-view class="h-100" />
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { mutateApp } from "@/helpers";

const route = useRoute();
const router = useRouter();
const store = useStore();

const windowSize = () => {
  let size = window.innerWidth;
  if (size < 576) return "xs";
  else if (size < 768) return "sm";
  else if (size < 992) return "md";
  else if (size < 1200) return "lg";
  else if (size < 1400) return "xl";
  else return "xxl";
};
mutateApp("windowSize", windowSize());

if (route.meta.requiresAuth) {
  if (!store.state.person.UserName) {
    router.push({ name: "Accueil" });
  }
}

onMounted(() => {
  document.addEventListener(
    "click",
    (event) => {
      mutateApp("clickPath", event.composedPath());
    },
    { capture: true }
  );
});
</script>

<style lang="scss">
@import "mdb-vue-ui-kit/src/scss/index.pro.scss";

$light-theme-primary: #f96a68;
$light-theme-secondary: #1d71b8;
// $light-theme-secondary: #f96a68;

$dark-theme-primary: #1d71b8;
$dark-theme-secondary: #951b81;

// $dark-theme-primary: #fc766a;
// $dark-theme-secondary: #1d71b8;

$light-theme: mdb-light-theme($light-theme-primary, $light-theme-secondary);

$dark-theme: mdb-dark-theme($dark-theme-primary, $dark-theme-secondary);

#app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;

  font-family: Roboto, Helvetica, Arial, sans-serif;

  &.light-theme {
    @include mdb-theme($light-theme);
    color: $gray-900 !important;
  }
  &.dark-theme {
    @include mdb-theme($dark-theme);
  }

  *:not(.fw-bold) {
    text-decoration-thickness: 1px !important;
  }

  input {
    color: currentColor !important;
  }

  // mdb
  &.light-theme {
    & .btn {
      font-weight: bold !important;
    }
    & .card-header {
      background-color: transparent !important;
    }
    & .opacity-85 {
      opacity: 85%;
    }
    & .text-muted {
      color: $gray-800 !important;
    }
  }

  &.dark-theme {
    .btn:hover {
      background-color: $white !important;
    }
    & .card-header {
      background-color: transparent !important;
    }
    & .text-muted {
      color: $gray-300 !important;
    }
  }

  // custom
  .aria-only {
    width: 0;
    height: 0;
  }
  .color-current {
    color: currentColor !important;
  }
  .main-view {
    overflow-y: scroll;
  }
  .h-fit {
    height: fit-content;
  }
  .w-fit {
    width: fit-content;
  }
  .above {
    z-index: 1250;
  }

  &.light-theme {
    & .gradient-box {
      background: linear-gradient(
        35deg,
        $light-theme-primary,
        $light-theme-secondary
      );

      & .bg-box {
        background-color: rgba($white, 0.75) !important;
        opacity: 0.999; // opacity: 1 doesn't work (???)
      }
    }

    & header {
      background-color: $white !important;
    }
  }

  &.dark-theme {
    & .gradient-box {
      background: linear-gradient(
        35deg,
        $dark-theme-primary,
        $dark-theme-secondary
      );
      & .bg-box {
        background-color: rgba($dark, 0.75) !important;
      }
    }

    & header,
    & .sidenav {
      background-color: $dark !important;
    }
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    overflow-y: overlay;
    border: none;
    border-radius: 5px;
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background: red;
    border-left: solid 1px red;
  }
  ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 5px;
  }
}
</style>
